.grid-container {
  margin: auto;
  display: grid;
  background-color: white;
  padding: 5px;
  max-width: 1200px;
  min-width: 800px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  grid-template-columns: 60% minmax(40%, 1fr);
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
}

.grid-item {
  padding: 10px;
  margin: 5px;
}
.orderform {
  grid-column: 1;
  grid-row: 1
}
.orderpreview {
  grid-column: 2;
  grid-row: 1;
  text-align: center;
}
.card-inside-preview {
  width: 100%;
  min-height: 150px;
  height: auto;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 5px;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  position: sticky;
  top: 0;
}