.order-form-card {
    width: 100%;
    font-size: 12px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
    padding: 10px;
    border-radius: 5px;
}

.row {
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: auto;
}

.license-input {
    text-transform: uppercase;
    width: 100px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 7px;
    margin: 10px 10px;
    border-radius: 5px;
    border: #292b2c solid 2px;
}

::placeholder {
    color: gray;
  }
.copy-button {
    background-color: transparent;
    padding: 7px;
    margin: 10px 10px;
    font-size: 12px;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
.copy-button:hover {
    background-color: #292b2c;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.copy-button:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.addorder-button {
    background-color: transparent;
    padding: 15px;
    margin: 10px 10px;
    font-size: 12px;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
    float: right;
}

.addorder-button:hover {
    background-color: #292b2c;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.addorder-button:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.customer-input {
    text-transform: uppercase;
    width: 350px;
    padding: 7px;
    margin: 10px 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    border: #292b2c solid 2px;
}
.parts-input {
    text-transform: uppercase;
    width: 400px;
    padding: 7px;
    margin: 10px 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    border: #292b2c solid 2px;
}

label {
    margin: 5px 10px;
    cursor: pointer;
}

label input[type="checkbox"] {
    display: none;
}

label span {
    position: relative;
    display: inline-block;
    background-color: transparent;
    padding: 7px;
    color: #4b5458;
    border: solid #4b5458 2px;
    border-radius: 5px;
    user-select: none;
    overflow: hidden;
}
label input[type="checkbox"]:checked ~ span {
    background-color: #292b2c;
    color: floralwhite;
}
