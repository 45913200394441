.modal-box-customer {
    position: relative;
    background-color: white;
    transform: translate(-50%, -50%);
    height: 220px;
    width: 600px;
    max-width: 600px;
    min-width: 300px;
    top: 30%;
    left: 50%;
    z-index: 101;
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
   }