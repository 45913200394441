form{
    position: relative;
    padding:  20px;
  }
  
  .form-header {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  
  .form-header h3{
    padding: 0;
  }
  
  .modal-wrapper {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; 
    }
    
  .modal-backdrop {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; 
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.4);
    }
    
  .modal-box-pickup-place {
     position: relative;
     background-color: white;
     transform: translate(-50%, -50%);
     height: 180px;
     width: 600px;
     max-width: 600px;
     min-width: 300px;
     top: 30%;
     left: 50%;
     z-index: 101;
     border-radius: 5px;
     box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    }
  
    .input-field {
      position: relative;
      display: flex;
      justify-content: center;
      font-size: 12px;
  }
  
  ::placeholder {
      color: black;
  }
  .input-field input[type=text] {
      width: 85%;
      padding: 7px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      border-radius: 3px;
      border: 2px solid black;
      font-size: 12px;
  }
  #placeName {
    text-transform: none !important
  }
  
  .input-field label {
      position: absolute;
      left: 49px;
      top: 12px;
      background-color: white;
      padding: 0px 5px;
      pointer-events: none;
      display: block;
      font-size: 12px;
      transition: 0.3s;
  }
  
  .input-field input[type=text]:focus + label,
  .input-field input[type=text]:valid + label {
      transform: translateY(-15px);
      font-size: 12px;
      color: #292b2c;
  }
  
  .input-field input[type=text]:focus {
      border: 2px solid rgb(80, 80, 80);;
      outline: none;
  }
  
  .submit-button {
    display: flex;
    justify-content: center;
    background-color: transparent;
    padding: 7px;
    font-size: 12px;
    margin: 5px auto;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }
  
  .submit-button:hover {
    background-color: #292b2c;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
    
  .submit-button:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }