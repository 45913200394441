.modal-box-pickup {
    position: relative;
    background-color: white;
    transform: translate(-50%, -50%);
    height: 310px;
    width: 600px;
    max-width: 600px;
    min-width: 300px;
    top: 30%;
    left: 50%;
    z-index: 101;
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
   }

.data {
    width: 85%;
    padding: 7px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 3px;
    border: 2px solid black;
    font-size: 12px;
}
.departure-data {
    width: 85%;
    padding: 7px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 3px;
    border: 2px solid black;
    font-size: 12px;
}
#partname {
    font-size: 12px;
    text-transform: none;
}
#customerFor {
    font-size: 12px;
    text-transform: none;
}
.submit-button-pickup {
    width: 75px;
    display: flex;
    justify-content: center;
    padding: 3px;
    margin: 5px auto;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
}
.submit-button-pickup:hover {
    background-color: #174729;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
    
  .submit-button-pickup:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .fa-whatsapp {
    color: #292b2c !important
  }
  .fa-whatsapp:hover {
      color: white !important
  }