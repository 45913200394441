.form-container {
    width: 100%;
    margin: auto;
    font-size: 12px;
}

.form-card {
    width: 500px;
    margin: 40px auto;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
}

h2 {
    margin: 10px;
    padding-top: 10px;
}
label {
    margin: 0px;
}

input[type=email], input[type=password] {
    text-align: center;
    width: 95%;
    padding: 7px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid #4b5458;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }

  .form-button {
    display: flex;
    justify-content: center;
    background-color: transparent;
    padding: 7px;
    font-size: 12px;
    margin: 5px auto;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }
  
  .form-button:hover {
    background-color: #292b2c;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
    
  .form-button:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .alert {
      background-color: rgb(252, 85, 85);
      text-align: center;
      width: 90%;
      padding: 7px;
      margin: 8px 0;
      border: 1px solid #ff0000;
      border-radius: 5px;
      display: inline-block;
      box-sizing: border-box;
      box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }
  