@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Open+Sans&family=Roboto&family=Ubuntu&display=swap);
.form-container {
    width: 100%;
    margin: auto;
    font-size: 12px;
}

.form-card {
    width: 500px;
    margin: 40px auto;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    background-color: white;
}

h2 {
    margin: 10px;
    padding-top: 10px;
}
label {
    margin: 0px;
}

input[type=email], input[type=password] {
    text-align: center;
    width: 95%;
    padding: 7px;
    margin: 8px 0;
    display: inline-block;
    border: 2px solid #4b5458;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }

  .form-button {
    display: flex;
    justify-content: center;
    background-color: transparent;
    padding: 7px;
    font-size: 12px;
    margin: 5px auto;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }
  
  .form-button:hover {
    background-color: #292b2c;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
    
  .form-button:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .alert {
      background-color: rgb(252, 85, 85);
      text-align: center;
      width: 90%;
      padding: 7px;
      margin: 8px 0;
      border: 1px solid #ff0000;
      border-radius: 5px;
      display: inline-block;
      box-sizing: border-box;
      box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }
  
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #292b2c;
    color: floralwhite;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 12px;
}

.title {
    font-size: 1.2rem;
    margin: 0.1rem;
}
.navbar-links ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.navbar-links li {
    list-style: none;
    display: block;
}

.links {
    color: floralwhite;
    text-decoration: none;
    padding: 20px 10px;
    cursor: pointer;
    display: inline;
}

.links:hover{
    color: grey;
    text-decoration: none;
}

svg {
    color: floralwhite;
}
#sign-out-icon :hover {
    fill: grey;
}
.modal-box-customer {
    position: relative;
    background-color: white;
    transform: translate(-50%, -50%);
    height: 220px;
    width: 600px;
    max-width: 600px;
    min-width: 300px;
    top: 30%;
    left: 50%;
    z-index: 101;
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
   }
form{
  position: relative;
  padding:  20px;
}

.form-header {
  display: flex;
  justify-content: center;
  padding: 0;
}

.form-header h3{
  padding: 0;
}

.modal-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0; 
  }
  
.modal-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0; 
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
.modal-box-part {
   position: relative;
   background-color: white;
   transform: translate(-50%, -50%);
   height: 180px;
   width: 600px;
   max-width: 600px;
   min-width: 300px;
   top: 30%;
   left: 50%;
   z-index: 101;
   border-radius: 5px;
   box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  }

  .input-field {
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 12px;
}

::-webkit-input-placeholder {
    color: black;
}

:-ms-input-placeholder {
    color: black;
}

::-ms-input-placeholder {
    color: black;
}

::placeholder {
    color: black;
}
.input-field input[type=text] {
    width: 85%;
    padding: 7px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 3px;
    text-transform: uppercase;
    border: 2px solid black;
    font-size: 12px;
}

.input-field label {
    position: absolute;
    left: 49px;
    top: 12px;
    background-color: white;
    padding: 0px 5px;
    pointer-events: none;
    display: block;
    font-size: 12px;
    transition: 0.3s;
}

.input-field input[type=text]:focus + label,
.input-field input[type=text]:valid + label {
    transform: translateY(-15px);
    font-size: 12px;
    color: #292b2c;
}

.input-field input[type=text]:focus {
    border: 2px solid rgb(80, 80, 80);;
    outline: none;
}

.submit-button {
  display: flex;
  justify-content: center;
  background-color: transparent;
  padding: 7px;
  font-size: 12px;
  margin: 5px auto;
  color: #4b5458;
  border: solid #4b5458 2px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
}

.submit-button:hover {
  background-color: #292b2c;
  color: floralwhite;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
  
.submit-button:active {
  background-color: rgb(80, 80, 80);
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.modal-box-pickup {
    position: relative;
    background-color: white;
    transform: translate(-50%, -50%);
    height: 310px;
    width: 600px;
    max-width: 600px;
    min-width: 300px;
    top: 30%;
    left: 50%;
    z-index: 101;
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
   }

.data {
    width: 85%;
    padding: 7px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 3px;
    border: 2px solid black;
    font-size: 12px;
}
.departure-data {
    width: 85%;
    padding: 7px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 3px;
    border: 2px solid black;
    font-size: 12px;
}
#partname {
    font-size: 12px;
    text-transform: none;
}
#customerFor {
    font-size: 12px;
    text-transform: none;
}
.submit-button-pickup {
    width: 75px;
    display: flex;
    justify-content: center;
    padding: 3px;
    margin: 5px auto;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
}
.submit-button-pickup:hover {
    background-color: #174729;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
    
  .submit-button-pickup:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .fa-whatsapp {
    color: #292b2c !important
  }
  .fa-whatsapp:hover {
      color: white !important
  }
form{
    position: relative;
    padding:  20px;
  }
  
  .form-header {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  
  .form-header h3{
    padding: 0;
  }
  
  .modal-wrapper {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; 
    }
    
  .modal-backdrop {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; 
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.4);
    }
    
  .modal-box-pickup-place {
     position: relative;
     background-color: white;
     transform: translate(-50%, -50%);
     height: 180px;
     width: 600px;
     max-width: 600px;
     min-width: 300px;
     top: 30%;
     left: 50%;
     z-index: 101;
     border-radius: 5px;
     box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
    }
  
    .input-field {
      position: relative;
      display: flex;
      justify-content: center;
      font-size: 12px;
  }
  
  ::-webkit-input-placeholder {
      color: black;
  }
  
  :-ms-input-placeholder {
      color: black;
  }
  
  ::-ms-input-placeholder {
      color: black;
  }
  
  ::placeholder {
      color: black;
  }
  .input-field input[type=text] {
      width: 85%;
      padding: 7px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      border-radius: 3px;
      border: 2px solid black;
      font-size: 12px;
  }
  #placeName {
    text-transform: none !important
  }
  
  .input-field label {
      position: absolute;
      left: 49px;
      top: 12px;
      background-color: white;
      padding: 0px 5px;
      pointer-events: none;
      display: block;
      font-size: 12px;
      transition: 0.3s;
  }
  
  .input-field input[type=text]:focus + label,
  .input-field input[type=text]:valid + label {
      transform: translateY(-15px);
      font-size: 12px;
      color: #292b2c;
  }
  
  .input-field input[type=text]:focus {
      border: 2px solid rgb(80, 80, 80);;
      outline: none;
  }
  
  .submit-button {
    display: flex;
    justify-content: center;
    background-color: transparent;
    padding: 7px;
    font-size: 12px;
    margin: 5px auto;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }
  
  .submit-button:hover {
    background-color: #292b2c;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
    
  .submit-button:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.grid-container {
  margin: auto;
  display: grid;
  background-color: white;
  padding: 5px;
  max-width: 1200px;
  min-width: 800px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  grid-template-columns: 60% minmax(40%, 1fr);
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
}

.grid-item {
  padding: 10px;
  margin: 5px;
}
.orderform {
  grid-column: 1;
  grid-row: 1
}
.orderpreview {
  grid-column: 2;
  grid-row: 1;
  text-align: center;
}
.card-inside-preview {
  width: 100%;
  min-height: 150px;
  height: auto;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 5px;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.order-form-card {
    width: 100%;
    font-size: 12px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
    padding: 10px;
    border-radius: 5px;
}

.row {
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: auto;
}

.license-input {
    text-transform: uppercase;
    width: 100px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 7px;
    margin: 10px 10px;
    border-radius: 5px;
    border: #292b2c solid 2px;
}

::-webkit-input-placeholder {
    color: gray;
  }

:-ms-input-placeholder {
    color: gray;
  }

::-ms-input-placeholder {
    color: gray;
  }

::placeholder {
    color: gray;
  }
.copy-button {
    background-color: transparent;
    padding: 7px;
    margin: 10px 10px;
    font-size: 12px;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  
.copy-button:hover {
    background-color: #292b2c;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.copy-button:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.addorder-button {
    background-color: transparent;
    padding: 15px;
    margin: 10px 10px;
    font-size: 12px;
    color: #4b5458;
    border: solid #4b5458 2px;
    cursor: pointer;
    border-radius: 5px;
    float: right;
}

.addorder-button:hover {
    background-color: #292b2c;
    color: floralwhite;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.addorder-button:active {
    background-color: rgb(80, 80, 80);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.customer-input {
    text-transform: uppercase;
    width: 350px;
    padding: 7px;
    margin: 10px 10px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    border: #292b2c solid 2px;
}
.parts-input {
    text-transform: uppercase;
    width: 400px;
    padding: 7px;
    margin: 10px 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
    border: #292b2c solid 2px;
}

label {
    margin: 5px 10px;
    cursor: pointer;
}

label input[type="checkbox"] {
    display: none;
}

label span {
    position: relative;
    display: inline-block;
    background-color: transparent;
    padding: 7px;
    color: #4b5458;
    border: solid #4b5458 2px;
    border-radius: 5px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    overflow: hidden;
}
label input[type="checkbox"]:checked ~ span {
    background-color: #292b2c;
    color: floralwhite;
}

.accordion-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    width: 100%;
}

.accordion {
    background-color: rgb(238, 238, 238);
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 3px;
    transition: background-color 0.2s ease;
    margin-bottom: 10px;
}

.accordion:focus {
    outline: none;
}
.accordion:hover,
.active {
    background-color: #292b2c;
    color: floralwhite;
}
.accordion-title {
    margin: 0px;
    padding: 7px;
    text-transform: uppercase;
    font-size: 12px;
}
.accordion-content {
    overflow: hidden;
    transition: max-height 0.6 ease;
    display: flex;
    align-items: flex-start;
}
.order-preview-row {
    align-items: center;
}

.order-preview-license {
    font-weight: bold;
    margin: 0;
}

.order-preview-customer {
    margin: 10px;
    font-weight: bold;
}

.order-preview-parts {
    margin: 0px auto 5px auto;
    font-size: 12px;
}
.row-list {
    cursor: pointer;
}
.orders-card {
    width: 100%;
    min-height: 80px;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }
  .orders-header{
    cursor: pointer;
    background-color: rgb(238, 238, 238);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .licese-plate {
    margin-bottom: 5px;
  }
  .customername{
    margin: 0px;
    padding-bottom: 15px;
  }
  .orders-card-content {
    display: flex;
    margin: 5px;
  }
  .orders-card-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100px;
    margin: 2px;
  }
  .orders-card-center {
    display: block;
    width: 70%;
    justify-content: center;
    margin: 2px;
  }
  .orders-card-parts {
    padding: 3px;
  }
  .orders-card-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100px;
    margin: 2px;
  }
.orders-footer {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: rgb(238, 238, 238);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
#ready-icon {
    cursor: pointer;
}
.fa-check:hover {
  color: #A7D3A6 !important
  }
#trash-icon {
  color: #292b2c;
  cursor: pointer;
}
.ready-button {
  background-color: white;
  border: none;
}


body {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    min-width: 500px;
    background-color: #E8E8E8;
    margin: 0;
    padding: 0;
}
.input-container {
    position: relative;
    display: flex;
    font-size: 12px;
    width: 350px;
    margin: 10px auto;
    border-radius: 5px;
    justify-content: center;
    background-color: white;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
}

::-webkit-input-placeholder {
    color: black;
}

:-ms-input-placeholder {
    color: black;
}

::-ms-input-placeholder {
    color: black;
}

::placeholder {
    color: black;
}
.input-search input[type=text] {
    padding: 7px 20px;
    margin: 10px 0;
    box-sizing: border-box;
    border-radius: 3px;
    text-transform: uppercase;
    border: 2px solid black;
    font-size: 12px;
}

.input-search label {
    position: absolute;
    left: 85px;
    top: 12px;
    background-color: white;
    padding: 0px 0px;
    pointer-events: none;
    display: block;
    font-size: 12px;
    transition: 0.3s;
}

.input-search input[type=text]:focus + label,
.input-search input[type=text]:valid + label {
    transform: translateY(-15px);
    font-size: 12px;
    color: #292b2c;
}

.input-search input[type=text]:focus {
    border: 2px solid rgb(80, 80, 80);;
    outline: none;
}
.container-grid {
    width: 800px;
    margin: auto;
    justify-content: center;
    text-align: center;
}
.clear {
    position: relative;
}
.allorderscount {
    text-align: center;
}

