.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #292b2c;
    color: floralwhite;
    padding: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 12px;
}

.title {
    font-size: 1.2rem;
    margin: 0.1rem;
}
.navbar-links ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.navbar-links li {
    list-style: none;
    display: block;
}

.links {
    color: floralwhite;
    text-decoration: none;
    padding: 20px 10px;
    cursor: pointer;
    display: inline;
}

.links:hover{
    color: grey;
    text-decoration: none;
}

svg {
    color: floralwhite;
}
#sign-out-icon :hover {
    fill: grey;
}