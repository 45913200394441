.orders-card {
    width: 100%;
    min-height: 80px;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5px;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  }
  .orders-header{
    cursor: pointer;
    background-color: rgb(238, 238, 238);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .licese-plate {
    margin-bottom: 5px;
  }
  .customername{
    margin: 0px;
    padding-bottom: 15px;
  }
  .orders-card-content {
    display: flex;
    margin: 5px;
  }
  .orders-card-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100px;
    margin: 2px;
  }
  .orders-card-center {
    display: block;
    width: 70%;
    justify-content: center;
    margin: 2px;
  }
  .orders-card-parts {
    padding: 3px;
  }
  .orders-card-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100px;
    margin: 2px;
  }
.orders-footer {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: rgb(238, 238, 238);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
#ready-icon {
    cursor: pointer;
}
.fa-check:hover {
  color: #A7D3A6 !important
  }
#trash-icon {
  color: #292b2c;
  cursor: pointer;
}
.ready-button {
  background-color: white;
  border: none;
}

