.input-container {
    position: relative;
    display: flex;
    font-size: 12px;
    width: 350px;
    margin: 10px auto;
    border-radius: 5px;
    justify-content: center;
    background-color: white;
    box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
}

::placeholder {
    color: black;
}
.input-search input[type=text] {
    padding: 7px 20px;
    margin: 10px 0;
    box-sizing: border-box;
    border-radius: 3px;
    text-transform: uppercase;
    border: 2px solid black;
    font-size: 12px;
}

.input-search label {
    position: absolute;
    left: 85px;
    top: 12px;
    background-color: white;
    padding: 0px 0px;
    pointer-events: none;
    display: block;
    font-size: 12px;
    transition: 0.3s;
}

.input-search input[type=text]:focus + label,
.input-search input[type=text]:valid + label {
    transform: translateY(-15px);
    font-size: 12px;
    color: #292b2c;
}

.input-search input[type=text]:focus {
    border: 2px solid rgb(80, 80, 80);;
    outline: none;
}
.container-grid {
    width: 800px;
    margin: auto;
    justify-content: center;
    text-align: center;
}
.clear {
    position: relative;
}
.allorderscount {
    text-align: center;
}
