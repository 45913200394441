.order-preview-row {
    align-items: center;
}

.order-preview-license {
    font-weight: bold;
    margin: 0;
}

.order-preview-customer {
    margin: 10px;
    font-weight: bold;
}

.order-preview-parts {
    margin: 0px auto 5px auto;
    font-size: 12px;
}
.row-list {
    cursor: pointer;
}