.accordion-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    width: 100%;
}

.accordion {
    background-color: rgb(238, 238, 238);
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 3px;
    transition: background-color 0.2s ease;
    margin-bottom: 10px;
}

.accordion:focus {
    outline: none;
}
.accordion:hover,
.active {
    background-color: #292b2c;
    color: floralwhite;
}
.accordion-title {
    margin: 0px;
    padding: 7px;
    text-transform: uppercase;
    font-size: 12px;
}
.accordion-content {
    overflow: hidden;
    transition: max-height 0.6 ease;
    display: flex;
    align-items: flex-start;
}